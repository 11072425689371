import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ 
    "slide",
    "controls",
    "akathisiaCheckbox",
    "mentalIllnessCheckbox",
    "back",
    "progress"
  ]

  static values = { errors: Boolean }

  initialize() {
    if (this.errorsValue) {
      this.controlsTargets.forEach(element => element.hidden = true)
      this.progressTargets.forEach(element => element.hidden = true)
    } else {
      this.index = 0
      this.slidesLength = this.slideTargets.length
      this.showCurrentSlide()
      this.akathisiaCheckboxTarget.parentNode.classList.add('hidden')
      this.mentalIllnessCheckboxTarget.parentNode.classList.add('hidden')
    }
  }

  next(e) {
    e.preventDefault()
    if (this.index === this.slideTargets.length - 1) return;
    this.index++
    this.showCurrentSlide()
  }

  previous(e) {
    e.preventDefault()
    if (this.index === 0) return;
    this.index--
    this.showCurrentSlide()
  }

  akathisiaCheckboxAndNext(e) {
    this.akathisiaCheckboxTarget.checked = true;
    this.akathisiaCheckboxTarget.parentNode.classList.remove('hidden')
    this.next(e)
  }

  mentalIllnessCheckboxandNext(e) {
    this.mentalIllnessCheckboxTarget.checked = true;
    this.mentalIllnessCheckboxTarget.parentNode.classList.remove('hidden')
    this.next(e)
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      index != this.index ? element.classList.add('hidden') : element.classList.remove('hidden')
    })
  }
}

